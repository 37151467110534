<template>
  <div class="tw-mb-10">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "TravelSchedulesRoute"
};
</script>
